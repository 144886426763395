import { AnyLink } from './create-link';

/**
 * A ParamMatcher.parser should return false if it does not match or an
 * object with a length indicating the number of chars used and the
 * The value of the parameter for this match
 */
export type MatcherParseResult<Type> = false | { length: number; value: Type };

/**
 * A parameter matcher "serializes" and conditionally "de-serializes"
 */
export type Matcher<Type> = [
  (toMatch: string) => MatcherParseResult<Type>,
  (input: Type) => string
];

export const getCurrentSegment = (toMatch: string) => {
  const nextSlashIndex = toMatch.indexOf('/');
  return nextSlashIndex === -1 ? toMatch : toMatch.substring(0, nextSlashIndex);
};

const encode = encodeURIComponent;
const decode = decodeURIComponent;

/**
 * Default matcher. Matches any string up to the next '/'. Empty strings are *valid*
 */
export const segmentMatcher: Matcher<string> = [
  (toMatch) => {
    const segment = getCurrentSegment(toMatch);
    return {
      length: segment.length,
      value: decode(segment)
    };
  },
  (path) => encode(path)
];

/**
 * Matches a number. Consumes the max number of characters that can be parsed as a number
 * using `Number()` as the parser
 */
export const numberMatcher = (
  min = -Infinity,
  max = Infinity
): Matcher<number> => [
  (toMatch) => {
    // We do a simple search to find the longest number that can be
    // parsed up until the end of the segment. This relies on the
    // platform parsing instead of maintaining our own charset and
    // number parsing rules, although its a bit less optimized
    let maybeNumberStr = getCurrentSegment(toMatch);
    let maybeNumber = NaN;
    for (
      ;
      maybeNumberStr.length;
      maybeNumberStr = maybeNumberStr.slice(0, -1)
    ) {
      maybeNumber = Number(maybeNumberStr);
      if (!isNaN(maybeNumber)) break;
    }
    if (isNaN(maybeNumber) || maybeNumber < min || maybeNumber > max) {
      return false;
    }
    return {
      length: maybeNumberStr.length,
      value: maybeNumber
    };
  },
  (num) => {
    if (num < min || num > max) {
      throw new Error(`${num} not in [${min}..${max}]`);
    }
    return `${num}`;
  }
];

/**
 * Matches the rest of the url, no matter what it is.
 * Careful, there is no encoding of this param, it is
 * expected to already be encoded
 */
export const starMatcher: Matcher<string> = [
  (toMatch: string) => ({
    length: toMatch.length,
    value: toMatch
  }),
  (path) => path
];

/**
 * Matches a RegExp on the current string. The chars used for the match will be deducted.
 * Notes:
 *
 * * Must match the beginning of the search string, even if not using ^
 * * Do not use '$' unless you always plan to use the rest of the URL
 * * It has the potential to match multiple `/` segments, *not just until the next `/`*
 * * Do not use the /g flag, we only support matching the first instance
 */
export const regexMatcher = (regexp: RegExp): Matcher<string> => [
  (toMatch) => {
    const match = decode(toMatch).match(regexp);
    return !match || match.index
      ? false
      : {
          // Find the proper length by re-encoding it
          length: encode(match[0]).length,
          value: match[0]
        };
  },
  (value) => {
    const match = value.match(regexp);
    if (!match || match.index) {
      throw new Error(`${value} doesn't match`);
    }
    return encode(value);
  }
];

/**
 * Compose and combine matchers by creating a link and creating a matcher from it
 *
 * Example:
 *
 * const simpleDateMatcher = linkMatcher(
 *   // The sample link to match
 *  createLink`${'year'}-${'month'}-${'day'}`({
 *    year: numberMatcher(1900, 2100),
 *    month: numberMatcher(1, 12),
 *    day: numberMatcher(0, 31)
 *  }),
 *  // Convert the matched values into a Date
 *  ({ year, month, day }) => new Date(year, month - 1, day),
 *  // Convert the date into the expected values to re-serialize
 *  (date: Date) => ({
 *    year: date.getFullYear(),
 *    month: date.getMonth() + 1,
 *    day: date.getDate()
 *  })
 * );
 */
export const linkMatcher = <
  /* The type that link match results in */
  InnerType extends object = object,
  /* The type that your parsers / formatters convert this InnerType to. */
  OuterType = InnerType
>(
  link: AnyLink<InnerType>,
  parse: (input: InnerType) => OuterType = (v) => v as any,
  format: (input: OuterType) => InnerType = (v) => v as any
): Matcher<OuterType> => [
  (toMatch) => {
    const intermediate = link.match(toMatch, false) as InnerType;
    if (!intermediate) return false;
    // Reconstruct the link to determine how many chars matched
    const { length } = link.url(intermediate);
    return {
      value: parse(intermediate),
      length
    };
  },
  (params) => link.url(format(params))
];

// TODO maybe backport to links?
export const jsonBase64Matcher: Matcher<object> = [
  (path) => {
    let value;
    path = getCurrentSegment(path);
    try {
      value = JSON.parse(atob(decodeURIComponent(path))).a;
    } catch (e) {
      return false;
    }
    return {
      length: path.length,
      value
    };
  },
  (input) => {
    return encodeURIComponent(btoa(JSON.stringify({ a: input })));
  }
];

// TODO maybe backport to links?
export const enumMatcher = <Enum extends string>(
  ...enums: Readonly<Enum[]>
): Matcher<Enum> => [
  (toMatch: string) => {
    const segment = getCurrentSegment(toMatch);
    const decoded = decodeURIComponent(segment) as Enum;
    if (enums.includes(decoded)) {
      return {
        length: segment.length,
        value: decoded
      };
    }
    return false;
  },
  (value) => {
    if (!enums.includes(value)) {
      throw new Error(`Enum ${enums.join(', ')} does not include ${value}`);
    }
    return encodeURIComponent(value);
  }
];
