import { LinkCreator } from './create-link';
import { Matcher, segmentMatcher } from './matchers';

export * from './create-link';
export * from './matchers';

/**
 * Create a type-safe link, fully able to process serial and de-serialization!
 *
 * TL;DR
 *
 * ```
 * const myRouteWithAParam = createLink`/my-route/${'param'}`()
 *
 * myRouteWithAParam.url({param: 'the value'}) // '/my-route/the%20value'
 * myRouteWithAParam.match('/my-route/can-go-backwards-too') // { param: 'can-go-backwards-too' }
 * ```
 *
 *
 * Fuller Explanation:
 *
 * We use template string literals in a cool way here. Template literal strings
 * are the strings that use back ticks ` instead  of single or double quotes. It
 * means you are using variables in the strings. Normally, you can use a template
 * string literal like this:
 *
 * ```
 * const name = 'Joel'
 * const myString = `Welcome, ${name}`
 * console.log(myString) // 'Welcome, Joel'
 * ```
 *
 * Instead, we are going to leverage a feature of JS that lets US specify how to use
 * those variables. We can smash the backticks right up to a function and remove the
 * parenthesis in order to get control over the variables:
 *
 * createLink`/my-link/${'param'}`();
 *
 * Read more on template literals:
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Template_literals
 *
 *
 * Any variables that you set in createLink, like 'param' above, should be inline string
 * values like that. *You should not reference any other actual variables, only static
 * strings!!*
 *
 * By default, the param will be any value up until a '/' segment separator. It can be
 * an empty string!
 *
 * You can customize the type of value matched by specifying a matcher for a given param:
 *
 * const link = createLink`/my-link/${'param'}`({
 *   param: numberMatcher()
 * });
 *
 * link.url({ param: 123 }) // Note that we specify a number and not a string
 *
 * There are a few other matchers and you can create your own too! look at `./matchers`
 * for more help.
 *
 *
 * Additionally, you can extend a link to create a child link:
 *
 * const parentLink = createLink`/id/${'idString'}`();
 * const childLink = parentLink.extend`/page/${'pageNumber'}`({
 *   pageNumber: numberMatcher()
 * });
 *
 * childLink.url({
 *   idString: 'ABC123',
 *   pageNumber: 3
 * }) // '/id/ABC123/page/3'
 *
 * This allows you to extend off of another link as if you created it inline.
 */
export const createLink: LinkCreator<object> = (
  parts: TemplateStringsArray,
  ...params: string[]
): any => (matchers: any = {}) => {
  // There will always be 1 more item in the parts array than the params array.
  // We will save it and treat it special as the "last" item
  const lastPart = parts[parts.length - 1];
  // Pair up every param and its name with the static string segment that precedes it
  const segments = params.map((paramName, index): [
    string,
    Matcher<any>,
    string
  ] => [
    parts[index].toLowerCase(),
    matchers[paramName] || segmentMatcher,
    paramName
  ]);

  const url = (linkToParams: any = {}): string =>
    (segments
      .map(
        ([staticPart, matcher, name]) =>
          staticPart + matcher[1](linkToParams[name])
      )
      .join('') + lastPart) as any;

  return {
    url,
    match: (url: string, exact = true): false | object => {
      const matchedParams: any = {};
      const isMatch = segments.every(([staticPart, matcher, name]) => {
        if (url.toLowerCase().startsWith(staticPart)) {
          url = url.substring(staticPart.length);
          const match = matcher[0](url);
          if (match !== false) {
            url = url.substring(match.length);
            matchedParams[name] = match.value;
            return true;
          }
        }
        return false;
      });
      return isMatch &&
        (exact
          ? url.toLowerCase() === lastPart.toLowerCase()
          : url.toLowerCase().startsWith(lastPart.toLowerCase()))
        ? matchedParams
        : false;
    },
    extend: (
      extendedParts: TemplateStringsArray,
      ...extendedParams: string[]
    ) => (extendedMatchers: object = {}) => {
      // Join the 2 static arrays together, joining the last element from
      // the first array and first element from the second
      const joinedParts = [
        ...parts.slice(0, -1),
        lastPart + extendedParts[0],
        ...extendedParts.slice(1)
      ] as any;
      // Join the matcher types together
      const joinedMatchers = {
        ...matchers,
        ...extendedMatchers
      };
      return (createLink as any)(
        joinedParts,
        ...params,
        ...extendedParams
      )(joinedMatchers);
    },
    withParams: (linkToParams: object = {}) =>
      (createLink as any)([url(linkToParams)])(matchers)
  };
};
